::-webkit-scrollbar {
	width:  5px;

}

::-webkit-scrollbar-thumb {
	background: var(--highlight);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgb(234, 234, 234);
}

.firm-search-wrapper {
	::-webkit-scrollbar-track {
		border-radius: 0 0 10px 10px;
		background: rgb(234, 234, 234);
	}
}
