// Import styles
@use 'ngx-toastr/toastr';
@use "styles/tailwind-groups";
@use "styles/scrollbar";


@layer base {
  @font-face {
    font-family: "SourceSansPro";
    font-weight: 300;
    font-style: normal;
    src: url("assets/font/SourceSansPro-Light.ttf");
  }

  @font-face {
    font-family: "SourceSansPro";
    font-weight: 400;
    font-style: normal;
    src: url("assets/font/SourceSansPro-Regular.ttf");
  }

  @font-face {
    font-family: "SourceSansPro";
    font-weight: 600;
    font-style: normal;
    src: url("assets/font/SourceSansPro-SemiBold.ttf");
  }

  @font-face {
    font-family: "SourceSansPro";
    font-weight: 700;
    font-style: normal;
    src: url("assets/font/SourceSansPro-Bold.ttf");
  }
}


body {
  // fix weird bub causing overflows to extend the page even with a fixed height
  overflow-y: clip;
}


.sidebar-bg {
  background-image: linear-gradient(162deg, var(--sidebar-gradient-start), var(--sidebar-gradient-middle), var(--sidebar-gradient-stop));
}


// remove dumb browser autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}


// Club-themes
html {
  //N4 CI
  --defaultHighlight: #5cb8b2;
  --defaultHighlight-dark: #008580;
  --defaultRed: #B11919;
  --defaultGreen: #3D7740;

  --defaultSidebar-light: #272727;
  --defaultSidebar-gradient-start: #142929;
  --defaultSidebar-gradient-middle: #080e14;
  --defaultSidebar-gradient-stop: #0d0e15;
  --defaultSidebar: #3c3c3b;
  --defaultSidebar-hover: #71717144;
  --defaultSidebar-text-color: white;
  --defaultSidebar-text-color-2: #6B7280;
  --defaultSidebar-border: #99999944;

  --defaultCompanyLogo: url("./assets/images/logo.svg");
  --defaultLoginBackground: url("./assets/images/background-default.png");
}

[data-theme] {
  --highlight: var(--defaultHighlight);
  --highlight-dark: var(--defaultHighlight-dark);
  --sso-red: var(--defaultRed);
  --sso-green: var(--defaultGreen);

  --sidebar: var(--defaultSidebar);
  --sidebar-light: var(--defaultSidebar-light);
  --sidebar-hover: var(--defaultSidebar-hover);
  --sidebar-gradient-start: var(--defaultSidebar-gradient-start);
  --sidebar-gradient-middle: var(--defaultSidebar-gradient-middle);
  --sidebar-gradient-stop: var(--defaultSidebar-gradient-stop);
  --sidebar-text-color: var(--defaultSidebar-text-color);
  --sidebar-text-color-2: var(--defaultSidebar-text-color-2);
  --sidebar-border: var(--defaultSidebar-border);

  --companyLogo: var(--defaultCompanyLogo);
  --loginBackground: var(--defaultLoginBackground);
}

[data-theme="CLOUDCITY"], [data-theme="DEFAULT"], [data-theme="BRI-ES1"] {
  --highlight: #5CB8B2;
  --highlight-dark: #008580;
  --sso-red: var(--defaultRed);
  --sso-green: var(--defaultGreen);

  --sidebar: #3c3c3b;
  --sidebar-light: #272727;
  --sidebar-hover: var(--defaultSidebar-hover);
  --sidebar-gradient-start: var(--defaultSidebar-gradient-start);
  --sidebar-gradient-middle: var(--defaultSidebar-gradient-middle);
  --sidebar-gradient-stop: var(--defaultSidebar-gradient-stop);
  --sidebar-text-color: var(--defaultSidebar-text-color);
  --sidebar-text-color-2: var(--defaultSidebar-text-color-2);
  --sidebar-border: var(--defaultSidebar-border);

  --companyLogo: var(--defaultCompanyLogo);
  --companyLogoSmall: var(--defaultCompanyLogoSmall);
  --loginBackground: var(--defaultLoginBackground);

}


[data-theme="AM"], [data-theme="AM_OM_DE"], [data-theme="AM_DE"], [data-theme="AM_AT"] {
  --highlight: #60B55A;
  --highlight-dark: #56b04f;
  --sso-red: var(--defaultRed);
  --sso-green: var(--defaultGreen);

  --sidebar: #252121;
  --sidebar-light: var(--defaultSidebar-light);
  --sidebar-hover: var(--defaultSidebar-hover);
  --sidebar-text-color: var(--defaultSidebar-text-color);
  --sidebar-text-color-2: var(--defaultSidebar-text-color-2);
  --sidebar-gradient-start: #1d451a;
  --sidebar-gradient-middle: #0b140c;
  --sidebar-gradient-stop: var(--defaultSidebar-gradient-stop);
  --sidebar-border: var(--defaultSidebar-border);

  --companyLogo: url("./assets/images/automeister.png");
  --loginBackground: var(--defaultLoginBackground);

}


[data-theme="POINTS"], [data-theme="PS_DE"] {
  --highlight: #0067B1;
  --highlight-dark: #005999;
  --sso-red: var(--defaultRed);
  --sso-green: var(--defaultGreen);

  --sidebar: #252121;
  --sidebar-light: var(--defaultSidebar-light);
  --sidebar-hover: var(--defaultSidebar-hover);
  --sidebar-text-color: var(--defaultSidebar-text-color);
  --sidebar-text-color-2: var(--defaultSidebar-text-color-2);
  --sidebar-gradient-start: #1f2d52;
  --sidebar-gradient-middle: #1e361e;
  --sidebar-gradient-stop: var(--defaultSidebar-gradient-stop);
  --sidebar-border: var(--defaultSidebar-border);

  --companyLogo: url("./assets/images/points.png");
  --loginBackground: var(--defaultLoginBackground);
}


[data-theme^="BRI-"] {
  --highlight: #ff0000;
  --highlight-dark: #940000FF;
  --sso-red: var(--defaultRed);
  --sso-green: var(--defaultGreen);

  --sidebar: #252121;
  --sidebar-light: var(--defaultSidebar-light);
  --sidebar-hover: var(--defaultSidebar-hover);
  --sidebar-text-color: #252121;
  --sidebar-text-color-2: #252121;
  --sidebar-gradient-start: #fff;
  --sidebar-gradient-middle: #fff;
  --sidebar-gradient-stop: #940000FF;
  --sidebar-border: var(--defaultSidebar-border);

  --companyLogo: url("./assets/images/firststop.png");
  --loginBackground: var(--defaultLoginBackground);
}
