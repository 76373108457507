// Import Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;



//HEADER
.h1-header {
  @apply text-darkgrey font-semibold text-1.5xl;
}

.h2-header {
  @apply text-xl text-darkgrey antialiased;
}

.h3-header {
  @apply text-lg text-darkgrey font-semibold tracking-wide antialiased;
}


//SIDEBAR
.a-sidebar {
  @apply flex items-center p-2 font-normal rounded-lg hover:bg-sidebar-hover cursor-pointer;
  &.admin {
    @apply hover:bg-sidebar;
  }

  &.active {
    @apply bg-sidebar-hover;
  }
}

.text-wrapper {
  @apply text-center text-darkgrey text-sm;
}



//INPUTS
.input-field {
  @apply text-slate-800 antialiased bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-highlight focus:border-highlight block p-2.5 mb-5 outline-none;
  &:disabled {
    cursor: not-allowed;
  }
}

.input-field-has-Error {
  @apply text-slate-800 antialiased bg-gray-50 border border-sso-red text-sm rounded-lg focus:ring-sso-red focus:border-sso-red block p-2.5 mb-3;
}

.label {
  @apply block leading-relaxed text-darkgrey-white3 text-xs;
}

.label-error-hint {
  @apply block leading-relaxed text-sm text-sso-red mt-[-0.5rem];
}

.label-checkbox {
  @apply block leading-relaxed text-darkgrey-white3 text-sm pl-2.5;
}

.input-checkbox {
  @apply w-4 h-4 text-highlight hover:cursor-pointer bg-gray-100 rounded border-gray-300 focus:ring-highlight focus:ring-2;
  &:disabled {
    @apply text-gray-300 bg-gray-300 hover:cursor-not-allowed;
  }
}


//BUTTONS

.button-highlight {
  @apply flex items-center justify-center rounded-lg text-white bg-highlight border border-highlight font-medium text-sm px-3 py-2 text-center hover:bg-highlight-dark transition-all;
  &:disabled {
    @apply cursor-not-allowed bg-gray-300 border-gray-300;
  }
}

.button-outline {
  @apply flex items-center justify-center rounded-lg text-highlight border border-highlight font-medium text-sm px-3 py-2 text-center hover:bg-highlight-dark hover:text-white transition-all;
  &:disabled {
    @apply cursor-not-allowed  border-gray-300 text-gray-300  hover:bg-white;
  }
}

.button-outline-sm {
  @apply flex items-center justify-center rounded-xl text-highlight border border-highlight font-medium text-sm px-2 py-1 text-center;
  &:disabled {
    @apply cursor-not-allowed  border-gray-300 text-gray-300;
  }
}

.file-input-button::file-selector-button {
  @apply button-highlight border-none;
}

.button-outline-red {
  @apply flex items-center justify-center rounded-lg text-sso-red border border-sso-red opacity-60 font-medium text-sm px-3 py-2 text-center hover:opacity-100 transition-all;
}

.back-button {
  @apply flex items-center rounded-lg text-gray-600 opacity-60 font-medium text-sm pr-3 py-2 text-center hover:opacity-100 transition-all;
}

.button-red {
  @apply flex items-center justify-center rounded-lg text-white bg-sso-red border border-sso-red font-medium text-sm px-3 py-2 text-center;
}

.button-green {
  @apply flex items-center justify-center rounded-lg text-white bg-green-500 border border-green-500 font-medium text-sm px-3 py-2 text-center;
}

.button-gray {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}


//CARD
.card-header-div {
  @apply flex flex-row items-center justify-between py-5 px-5 bg-white shadow-md;
}

.card-div {
  @apply p-5 flex flex-col flex-grow bg-white shadow-md rounded-lg;
}


//TABLE
.table-header-row {
  @apply w-full flex flex-row text-sm text-gray-500 font-medium uppercase bg-gray-200 rounded-t-md;
}

.table-row {
  @apply w-full flex flex-row text-sm text-gray-700 hover:bg-gray-100 border-x;
  &.selected {
    @apply bg-gray-100;
  }
}

.table-column {
  @apply flex flex-row items-center px-6 py-3;
}
